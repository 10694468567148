<template>
  <div class="ht">
    <van-nav-bar
      :title="$t('HT')"
      @click-left="onClickLeft"
    >
    <van-icon color="#fff" size="25" slot="left" name="arrow-left" />
    </van-nav-bar>
    <div v-html="content" class="container"></div>
  </div>
</template>

<script>
import './css/my.css'
export default {
  name: 'ht',
  data () {
    return {
      content: ''
    }
  },
  created () {
    // console.log(this.$route.params.content)
    this.content = this.$route.params.content
  },
  methods: {
    onClickLeft () {
      this.$router.go(-2)
    }
  }
}
</script>

<style scoped>
.container {
  font-size: 16px;
}
.ht >>> .van-nav-bar{
  background-color: #00BFFF
}
.ht >>> .van-nav-bar__title {
  color: #fff;
}
</style>
